import React from 'react';
import './intro.scss';

// Data
import data from "./../../content/data.json";

export default class Intro extends React.Component {
    render() {
        return (
            <section 
                className="introtext"
                dangerouslySetInnerHTML={ {__html: data.pageIntro} }>
            </section>
        )
    }
}