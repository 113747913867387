import React from 'react';

// CSS
import './footer.scss';
import './button-top.scss';

// Data
import data from "./../../content/data.json";

export default class Footer extends React.Component {
    render() {
        return (
            <footer 
                id="footer" 
                className="footer"
                >
                <span 
                    dangerouslySetInnerHTML={ {__html: data.footer} }
                    >
                </span>
                <a 
                    href="#top" 
                    className="top" 
                    title="Top" 
                    aria-label="Scroll back to the top"
                    >
                        <span>&#8250;</span>
                </a>
            </footer>
        )
    }
}