import React from 'react';
import throttle from 'lodash/throttle';

import './sticky.scss';

export default class extends React.Component {
    init() {
        window.onscroll = throttle(() => {
            const scrollpos = window.scrollY;
            const header = document.getElementById("header");
            const app = document.getElementById("root");
            if (scrollpos >= header.offsetHeight) {
                app.classList.add("js-header-sticky");
            }
            else {
                app.classList.remove("js-header-sticky");
            }
        }, 10);
    }
}
