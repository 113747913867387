import React from 'react';
import SmoothScroll from 'smooth-scroll';
import StickyHeader from './components/header/sticky';

// CSS - normalize.css - import vendor css
import 'normalize.css/normalize.css';

// CSS - Variables
import './components/variables.scss';

// CSS - Import all custom CSS styles - should be after all vendor css
import './components/base/bg.scss';
import './components/base/layout.scss';
import './components/base/typography.scss';
import './components/base/helpers.scss';
import './components/base/animations.scss';
import './components/mixins/scrollbars.scss';

// CSS - IE Fixes
import './components/ie/ie.scss';

// CSS - Print styles
import './components/base/print.scss';

// React Components
import Header from './components/header';
import Footer from './components/footer';
import Intro from './components/intro';
import Content from './components/content';

// JS - Smooth scroll
// eslint-disable-next-line
const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 400,
    easing: 'easeOutQuad',
    offset: function (anchor, toggle) {
        const headerHeight = document.getElementById('header').getBoundingClientRect().height;
        return headerHeight;
    }
});

// Component - Sticky Header
const sticky = new StickyHeader();
sticky.init();

// App
function App() {

    return (
        <div className="App" >
            <Header />
            <main id="main">
                <Intro />
                <Content />
            </main>
            <Footer />
            <div className="bg"></div>
        </div>
    );
}

export default App;
