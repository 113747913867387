import React from 'react';
import LazyLoad from 'react-lazyload';
import { forceCheck } from 'react-lazyload';

import './content.scss';
import './tech.scss';
import './nav.scss';

// Data
import data from "./../../content/data.json";
const techUsed = data.techUsed;
const clients = data.clients;

export default class ContentListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            techFilter: ""
        }
    }
    componentDidUpdate() {
        forceCheck();
    }
    renderTech = () => {
        let filterShowAll = "";
        if (this.state.techFilter === "") {
            filterShowAll = "active"
        }
        return (
            <section className="tech">
                <div>
                    <button
                        key="ShowAll"
                        aria-label="Show all sites"
                        className={filterShowAll}
                        onClick={() => {
                            this.setState({ techFilter: "" })
                        }}
                    >
                        Show All
                    </button>
                    {
                        techUsed
                            .sort((a, b) => a.localeCompare(b))
                            .map((item) => {
                                let activeItem = "";
                                if (this.state.techFilter === item) {
                                    activeItem = "active"
                                }
                                return (
                                    <button
                                        key={item}
                                        aria-label="Filter portfolio by"
                                        className={activeItem}
                                        onClick={() => {
                                            this.setState({ techFilter: item })
                                        }}>
                                        {item}
                                    </button>
                                )
                            })
                    }
                </div>
            </section>
        )
    }

    renderNav = () => {
        let clientsFiltered = clients.filter(clients => clients.tech.includes(this.state.techFilter));
        let clientsTotal = clientsFiltered.length;
        if (clientsTotal < 1) {
            clientsFiltered = clients;
        }
        if (clientsTotal === 0) {
            return (
                <section className="nav">
                    <nav id="nav">
                        {
                            clientsFiltered.map((item) => {
                                return (
                                    <a
                                        key={item.name}
                                        href={`#client-${item.name}`}
                                        aria-label={`Scroll to client: ${item.title}`}
                                    >
                                        <picture>
                                            <source srcSet={require(`./../../assets/img/clients/thumb-${item.name}.webp`)} type="image/webp" />
                                            <img src={require(`./../../assets/img/clients/thumb-${item.name}.jpg`)} alt={item.title} />
                                        </picture>
                                        <span>
                                            <em>
                                                {item.title}
                                            </em>
                                        </span>
                                    </a>
                                )
                            })
                        }
                    </nav>
                </section>
            )
        } else {
            return null
        }
    }

    renderContent = () => {
        let clientsFiltered = clients.filter(clients => clients.tech.includes(this.state.techFilter))
        let clientsTotal = clientsFiltered.length;
        if (clientsTotal < 1) {
            clientsFiltered = clients;
        }

        return (
            <section id="content" className="content">
                {
                    clientsFiltered.map((item) => {
                        const techUsed = item.tech;
                        if (item.url) {
                            return (
                                <article
                                    key={item.name}
                                    id={`client-${item.name}`}
                                >
                                    <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label={`Open ${item.title}'s website in a new tab `}
                                    >
                                        <header>
                                            <h2>
                                                {item.title}
                                                <span>www</span>
                                            </h2>
                                        </header>
                                        <LazyLoad offset={100} once>
                                            <picture>
                                                <source srcSet={require(`./../../assets/img/clients/client-${item.name}.webp`)} type="image/webp" />
                                                <img src={require(`./../../assets/img/clients/client-${item.name}.jpg`)} alt={item.title} />
                                            </picture>
                                        </LazyLoad>
                                        <footer>
                                            <ul>
                                                {techUsed.map((item) => {
                                                    return <li key={item}>{item}</li>
                                                })}
                                            </ul>
                                        </footer>
                                    </a>
                                </article>
                            )
                        }
                        else {
                            return (
                                <article
                                    key={item.name}
                                    id={`client-${item.name}`}
                                >
                                    <header>
                                        <h2>{item.title}</h2>
                                    </header>
                                    <LazyLoad offset={100} once>
                                        <picture>
                                            <source srcSet={require(`./../../assets/img/clients/client-${item.name}.webp`)} type="image/webp" />
                                            <img src={require(`./../../assets/img/clients/client-${item.name}.jpg`)} alt={item.title} />
                                        </picture>
                                    </LazyLoad>
                                    <footer>
                                        <ul>
                                            {techUsed.map((item) => {
                                                return <li key={item}>{item}</li>
                                            })}
                                        </ul>
                                    </footer>
                                </article>
                            )
                        }
                    })
                }
            </section>
        )
    }

    render() {
        return [
            <>
                <this.renderTech />
                <this.renderNav />
                <this.renderContent />
            </>
        ]
    }
}