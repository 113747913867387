import React from 'react';

// Images
import { ReactComponent as Logo } from './../../assets/img/logo.svg';
import { ReactComponent as IconGithub } from './../../assets/img/icon-github.svg';
import { ReactComponent as IconMail } from './../../assets/img/icon-mail.svg';
import { ReactComponent as IconMoon } from './../../assets/img/icon-moon.svg';
import { ReactComponent as IconSun } from './../../assets/img/icon-sun.svg';

// CSS
import './header.scss';

// Data
import data from "./../../content/data.json";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lightMode: false
        }
    }
    toggleTheme = () => {
        this.setState(prevState => ({
            lightMode: !prevState.lightMode
        }));
        document.getElementById("root").className=this.state.lightMode ? '' : 'theme-light';
    }
    render() {
        const githubLink = data.githubLink;
        const contactEmail = data.contactEmail;
        const contactEmailHeading = data.contactEmailHeading;
        const contactHref = `mailto:${contactEmail}?Subject=${contactEmailHeading}`;
        return (
            <header 
                id="header" 
                className="header">
                <a 
                    href="#top" 
                    className="logo" 
                    title="Voras.co.uk homepage" 
                    aria-label="Home - Back to top">
                    <Logo />
                </a>
                <div className="toolbar">
                    <button 
                        aria-label="Toggle Theme" 
                        className={
                            this.state.lightMode ? 
                            "theme-light" : "theme-dark"
                        } 
                        onClick={this.toggleTheme}
                        >
                            {this.state.lightMode ? 
                                <IconMoon /> : <IconSun />
                            } 
                    </button>
                    <a 
                        className="link github" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        href={githubLink} 
                        title="Github"
                        >
                            <IconGithub />
                    </a>
                    <a 
                        className="link contact" 
                        href={contactHref} 
                        title={contactEmail} 
                        aria-label="Contact me by email"
                        >
                            <IconMail />
                    </a>
                </div>
                <div className="bg"></div>
            </header>
        )
    }
}